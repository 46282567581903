.nav-bar {
    display: flex;

    justify-content: space-between; /* Adjust spacing between elements */
  align-items: center;
    background-color: #eee;
    height: 50px;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 999;
}
.clock{
    display:flex;
    justify-content: end !important;
 
 
 
}
.img-nav-logo{
    display: flex;
    margin:0 auto;
}




