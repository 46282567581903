.product-container {
  display: flex;
  /* justify-content: center; */
  height: 100vh;
  padding: 20px;
  margin-top: 50px;

}

.card-row {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 10px;

  /* Makes the row take up remaining space */
}

.card-hea {
  display: flex;
  flex-direction: row;

}


.card {
  flex-direction: row;
  margin: 3px;
  height: 40px;
  width: 100%;

  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);


}

.card-content {
  padding: 20px;
  display: flex;
  align-self: center;
  width: 100%;
}

.card-content-hea {

  display: flex;
  align-self: center;
  width: 100%;

}

.card-content-name {

  display: flex;
  border-radius: 5px;
  width: 250px;
  background-color: #50C878;
  padding: 3px;
  margin: auto;
  color: #fff;
  font-size: 11px;
}
.card-content-name-table {

  display: flex;
  border-radius: 5px;
  width: 300px;
  background-color: #50C878;
  padding: 2px;
  margin: auto;
  color: #fff;
  font-size: 13px;
}
.card1-content-name {


  border-radius: 5px;
  width: max-content;
  /* background-color: #50C878; */
  padding: 3px;
  font-weight: bold;
  /* color: #fff; */
}


/* Optional: Add hover effect */
.card:hover {
  background-color: lightgoldenrodyellow;
  transition: background-color 0.3s ease;
}

.header-content {
  display: flex;
  align-items: center;
  font-size: 13px;
}

.header-content img {
  margin-right: 5px;


}




.input-box {
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  padding: 2px;
  width: 60px;

  /* Adjust the width as needed */
}

.noData{
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
  font-weight: 600;
  margin-top: 30px;
}



.search-container {
  position: relative;
  width: 40%;
  border-radius: 50px;
  background: linear-gradient(135deg, rgb(218, 232, 247) 0%, rgb(214, 229, 247) 100%);
  display: flex;
  justify-content: center;
  align-items: center;

}

.search-container::after,
.search-container::before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: inherit;
  position: absolute;
}

.search-container::before {
  top: -1px;
  left: -1px;
  background: linear-gradient(0deg, rgb(218, 232, 247) 0%, rgb(255, 255, 255) 100%);
  z-index: -1;
}

.search-container::after {
  bottom: -1px;
  right: -1px;
  background: linear-gradient(0deg, rgb(163, 206, 255) 0%, rgb(211, 232, 255) 100%);
  /* box-shadow: rgba(79, 156, 232, 0.7019607843) 3px 3px 5px 0px, rgba(79, 156, 232, 0.7019607843) 5px 5px 20px 0px; */
  z-index: -2;
}

.input {
  padding: 10px;
  width: 100%;
  background: linear-gradient(135deg, rgb(218, 232, 247) 0%, rgb(214, 229, 247) 100%);
  border: none;
  color: #000;
  font-size: 13px;
  border-radius: 50px;
}

.input:focus {
  outline: none;
  background: linear-gradient(135deg, rgb(239, 247, 255) 0%, rgb(214, 229, 247) 100%);
}

.search__icon {
  width: 50px;
  aspect-ratio: 1;
  border-left: 2px solid white;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-radius: 50%;
  padding-left: 12px;
  margin-right: 10px;
}

.search__icon:hover {
  border-left: 3px solid white;
}

.search__icon path {
  fill: white;
}

.CartBtn {
  width: 120px;
  height: 30px;
  border-radius: 6px;
  border: none;
  background-color: rgb(255, 208, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition-duration: .5s;
  overflow: hidden;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.103);
  position: relative;
}

.IconContainer {
  position: absolute;
  left: -50px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 2;
  transition-duration: .5s;
}

.icon {
  border-radius: 1px;
}

.text {
  height: 100%;
  width: fit-content;
  display: flex;
  align-items: center;
  padding-top: 15px;
  justify-content: center;
  color: rgb(17, 17, 17);
  z-index: 1;
  transition-duration: .10s;
  font-size: 1.02em;
  font-weight: 600;
}

.CartBtn:hover .IconContainer {
  transform: translateX(58px);
  border-radius: 40px;
  transition-duration: .5s;
}

.CartBtn:hover .text {
  transform: translate(10px, 0px);
  transition-duration: .5s;
}

.CartBtn:active {
  transform: scale(0.95);
  transition-duration: .5s;
}
.modal-success{
  font-weight: 500;
  color: green;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.displayBtn{
  width: 120px;
  height: 30px;
  border-radius: 6px;
  border: none;
  background-color: rgb(255, 208, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition-duration: .5s;
  overflow: hidden;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.103);
  position: relative;
}
.selected-rows-container {
  border-bottom: 1px dotted #000; /* Adjust the border style as needed */
 
}
.tot-price{
  border-radius: 2px;
  width: 200px;
  background-color: lightsalmon;
  padding: 3px;
  margin:  5px auto ;
  color: #fff; 
}

