.container {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    height: 100vh;
}



.main-card {
    background-color: #fff;
    height: 400px;
    width: 400px;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-text {
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    margin: 10px;

}

.login-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 300px;
    justify-content: center;
    margin: 20px;
}


.form-input:focus {
    border: none;
    border-radius: 0;
    border-bottom: 2px solid #b7b9ba;
    box-shadow: none; 
    outline: none !important; /* Ensure outline is removed for focused state */
}
/* .login-button{
width:150px;
display: flex;
justify-content: center;
align-self: center;
}
.login-button:hover{
    width:300px;
    display: flex;
    justify-content: center;
    align-self: center;
    } */
.Logo{
    position: absolute;
    right:10px;
    top:15px;

}
.sub-Logo{
    position: absolute;
    right:15px;
    top:50px;
    padding: 10px;
   
}
.error{
color:red;
display: flex;
align-items: center;
justify-content: center;
}
.web-link{
    font-size: 10px;
    font-weight: 500;
    margin-left:5px;
}
.web-link:hover{
    font-size: 10px;
    font-weight: 500;
    color:blue;
    margin-left:5px;
    text-decoration: underline;
    cursor: pointer;
}