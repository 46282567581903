.cate-name{
    display:flex;
    flex-direction: row;
    gap:50px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.Cate-container{
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    height: 40vh;
}
.product {
    transition: transform 0.3s ease;
  }
  
  .product:hover {
    transform: scale(1.2); 
  }

  .product-container-home {
    position: relative;
    display: inline-block;
  }
  
  .product-details {
    display: flex;
  justify-content: center;
  align-items: center;
    position: absolute;
    bottom: -60%;
    left: 50%;
    transform: translateX(-50%);

    padding: 10px;
    border-radius: 5px;
    transition: bottom 0.3s ease;
    visibility: hidden;
    width:100%;
  }
  
  .product-container-home:hover .product-details {
    bottom: 5;
    visibility: visible;
  }
  
  
  